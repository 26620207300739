<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    left
  >
    <template #button-content>
        <!-- <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          /> -->
      <span class="">{{ currentLocale.name }}</span>
      <feather-icon icon="ChevronDownIcon" size="20" />
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
import ElementIcon from "@/components/elements/Icon.vue";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BImg,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    ElementIcon,
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  methods: {
    setLocale(param) {
      this.$i18n.locale = param;
      localStorage.setItem("lang", param);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "Arabic",
      },
      {
        locale: "ch",
        img: require("@/assets/images/flags/ch.jpg"),
        name: "Chinese",
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "fr",
        img: require("@/assets/images/flags/fr.png"),
        name: "French",
      },
      {
        locale: "de",
        img: require("@/assets/images/flags/de.png"),
        name: "German",
      },
      {
        locale: "ja",
        img: require("@/assets/images/flags/ja.png"),
        name: "Japanese",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Spanish",
      },
      {
        locale: "tu",
        img: require("@/assets/images/flags/tu.png"),
        name: "Turkish",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>
