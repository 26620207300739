export default [
  // {
  //   header: "Home",
  // },
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Dental News",
    route: "news",
    icon: "InfoIcon",
    permission: ["backoffice-news.list", "backoffice-news.show-all"],
  },
  {
    title: "Dental Twin Coins",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Coupon codes",
        route: "coupon-codes",
        permission: [
          "backoffice-coupon-code.list",
          "backoffice-coupon-code.show-all",
        ],
      },
      {
        title: "DentalTwin Coins Packages",
        route: "packages",
        permission: "backoffice-package.list",
      },
    ],
  },
  {
    title: "Customers",
    icon: "InfoIcon",
    children: [
      {
        title: "Company data",
        route: "customers",
        permission: ["backoffice-company.list", "backoffice-company.show-all"],
      },
      {
        title: "Organizational units",
        route: "unit",
        permission: "backoffice-unit.list",
      },
      {
        title: "Users",
        route: "users",
        permission: "user.list",
      },
      {
        title: "Referrals",
        route: "referral",
        permission: "backoffice-referral.list",
      },
    ],
  },

  {
    title: "Case List",
    route: "my-cases",
    icon: "ListIcon",
    permission: [
      "backoffice-case-instance.list",
      "backoffice-case-instance.show-all",
    ],
  },
  {
    title: "Payment History",
    route: "payment-history",
    icon: "ClockIcon",
    permission: [
      "backoffice-payment-history.list",
      "backoffice-payment-history.show-all",
    ],
  },
  {
    title: "Invoices",
    route: "invoices",
    icon: "FileTextIcon",
    permission: ["backoffice-invoice.list", "backoffice-invoice.show-all"],
  },
  {
    title: "Global Configuration",
    route: "global-configuration",
    icon: "SettingsIcon",
    permission: "backoffice-global-configuration.list",
  },

  {
    title: "Ticket System",
    route: "tickets",
    icon: "CheckSquareIcon",
    permission: ["backoffice-ticket.list", "backoffice-ticket.show-all"],
  },
];
